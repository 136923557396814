import PageHeadingContainer from "../../components/PageHeadingContainer/PageHeadingContainer";
import {Heading1} from "../../components/Heading/Heading";
import React, {useEffect, useState} from "react";
import {Spinner, SpinnerOverlay, SpinnerWrapper} from "../../components/Spinner/Spinner";
import {Await, defer, Link, useAsyncValue, useFetcher, useLoaderData, useNavigation, useParams, useRouteLoaderData} from "react-router-dom";
import {Text} from "../../components/Text/Text";
import styled from "styled-components";
import Pagination from "../../components/Pagination";
import {Table, TableHead, TableHeadCell, TableHeadRow, TableRegisterButton, TableRow, TableRowButton, TableRowCell, TableRowInner, TrashIcon} from "../../components/Table/Table";
import {deleteData, fetchProtectedData, postData} from "../../api/fetch";
import dayjs from "dayjs";
import {FilterButton} from "./Partials/FilterButton";
import qs from "qs";
import ConfirmationModal, {CancelButton} from "../../components/Modal/ConfirmationModal";
import {useOpenModal} from "../../hooks/useOpenModal";
import {Button} from "../../components/Button/Button";
import {Permissions, Statuses} from "../../constants/enums";
import {checkRequiredAccount} from '../Root/Root';
import {checkAccountPermissions} from '../../utils/helpers';
import {AppliedFilterList} from '../../components/AppliedFilters/AppliedFilterList';
import {ExportButton} from './Partials/ExportButton';

const StyledButton = styled(Button)`
    border: none;
    width: 100%;
    margin-top: 20px;
`;

const PatientLink = styled(Link)`
    color: var(--color-blue-50);
    font-weight: var(--fw-bold);
    text-decoration: underline;
    cursor: pointer;
`;

const PageHeadingInnerContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

const Buttons = styled.div`
    display: flex;
    gap: 20px;
`;

const RelativeWrapper = styled.div`
    position: relative;
`;

export async function reservationsAction({ request }) {
    let formData = await request.json()
    let intent = formData.intent

    switch (intent) {
        case "remove-reservation": {
            delete formData.intent
            return await deleteData(request, `reservation/${formData.id}`)
        }
        case "register-reservation": {
            delete formData.intent
            return await postData(request, `reservation/${formData.id}/register`, JSON.stringify({}))
        }
        default:
            return {"default": true}
    }
}

export async function reservationsLoader({ request, params: { status } }) {
    await checkRequiredAccount([Permissions.PORTAL_ACCESS_RESERVATIONS, Permissions.PARTICIPANT_VIEW]);

    if (!['reserved', 'registered', 'history'].includes(status)) {
        throw new Response("Not found", { status: 404});
    }

    const url = new URL(request.url);
    const searchParams = new URLSearchParams(url?.search)

    const page = searchParams.get("page") ?? 1;
    const fullName = searchParams.get("fullName") ?? null;
    const medicoreID = searchParams.get("medicoreID") ?? null;
    const birthDate = searchParams.get("birthDate") ?? null;
    const location = searchParams.getAll("location") ?? null;
    const appointmentFrom = searchParams.get("appointmentFrom") ?? null;
    const appointmentUntil = searchParams.get("appointmentUntil") ?? null;

    const queryString = qs.stringify({
        ...(page && {page}),
        ...(fullName && {fullName}),
        ...(medicoreID && {medicoreID}),
        ...(birthDate && {birthDate}),
        ...(location && {location}),
        ...(appointmentFrom && {appointmentFrom}),
        ...(appointmentUntil && {appointmentUntil})
    }, { arrayFormat: 'comma' });

    const reservationsPromise = fetchProtectedData(request, `reservation/${status}${queryString ? `?${queryString}` : ""}`)

    return defer({reservationsPromise})
}

const Reservations = () => {
    const {locationData} = useRouteLoaderData("portal");
    const {reservationsPromise} = useLoaderData()

    const {isOpen: removeReservationModalOpen, handleOpen: handleRemoveReservationModalOpen, handleClose: handleRemoveReservationModalClose} = useOpenModal()
    const [removeReservationId, setRemoveReservationId] = useState(null)

    const {isOpen: registerReservationModalOpen, handleOpen: handleRegisterReservationModalOpen, handleClose: handleRegisterReservationModalClose} = useOpenModal()
    const [registerReservationId, setRegisterReservationId] = useState(null)

    const {status} = useParams()

    const fetcher = useFetcher()
    const [error, setError] = useState(false)
    const [requestStatus, setRequestStatus] = useState(Statuses.IDLE)

    const title = () => {
        switch (status) {
            case "reserved": return "Nog te verwerken reserveringen"
            case "registered": return "Reeds verwerkte reserveringen"
            case "history": return "Definitief verwerkte reserveringen"
            default: return ""
        }
    }

    useEffect(() => {
        if (Boolean(removeReservationId)) { handleRemoveReservationModalOpen() } else { handleRemoveReservationModalClose()}
        //eslint-disable-next-line
    }, [removeReservationId]);

    useEffect(() => {
        if (Boolean(registerReservationId)) { handleRegisterReservationModalOpen() } else { handleRegisterReservationModalClose() }
        //eslint-disable-next-line
    }, [registerReservationId]);

    const doRemoveReservation = () => {
        setRequestStatus(Statuses.SUBMITTING)
        setError(false)

        fetcher.submit({
            intent: "remove-reservation",
            id: removeReservationId
        }, { method: "POST", encType: "application/json", action: `/reservation/reservations/${status}`})
    }

    const doRegisterReservation = () => {
        setRequestStatus(Statuses.SUBMITTING)
        setError(false)

        fetcher.submit({
            intent: "register-reservation",
            id: registerReservationId
        }, { method: "POST", encType: "application/json", action: `/reservation/reservations/${status}`})
    }

    useEffect(() => {
        if(fetcher?.state === "idle") {
            if(fetcher?.data?.error) {
                setRequestStatus(Statuses.IDLE);
                return setError(true);
            }

            if(fetcher?.data || fetcher?.data === "") {
                setRequestStatus(Statuses.IDLE);
                setRemoveReservationId(null)
                setRegisterReservationId(null)
            }
        }
        //eslint-disable-next-line
    }, [fetcher]);

    return (
        <>
            <PageHeadingContainer $flexDirection="column" $alignItems="start">
                <PageHeadingInnerContainer>
                    <Heading1>{title()}</Heading1>
                    <Buttons>
                        {status === "reserved" &&
                            <ExportButton />
                        }
                        <FilterButton
                            locationData={locationData}
                        />
                    </Buttons>
                </PageHeadingInnerContainer>
                <AppliedFilterList locationData={locationData} />
            </PageHeadingContainer>

            <React.Suspense fallback={
                <SpinnerWrapper>
                    <Spinner />
                </SpinnerWrapper>
            }>
                <Await resolve={reservationsPromise} errorElement={<Text $error>Er is iets misgegaan, probeer het opnieuw.</Text>}>
                    <DeferredReservations status={status} removeReservation={setRemoveReservationId} registerReservation={setRegisterReservationId} />
                </Await>
            </React.Suspense>

            <ConfirmationModal isOpen={removeReservationModalOpen} handleClose={() => setRemoveReservationId(null)}>
                <ConfirmationModal.Header>Reservering verwijderen</ConfirmationModal.Header>
                <Text $noMargin $align="center">Weet je zeker dat je de reservering wilt verwijderen?</Text>
                <StyledButton type="button" onClick={() => doRemoveReservation()} disabled={requestStatus !== Statuses.IDLE} loading={requestStatus !== Statuses.IDLE}>Ja, verwijderen</StyledButton>
                <CancelButton onClick={() => setRemoveReservationId(null)}>Annuleer</CancelButton>
                {error && <Text $error>Er is iets misgegaan, probeer het opnieuw.</Text>}
            </ConfirmationModal>

            <ConfirmationModal isOpen={registerReservationModalOpen} handleClose={() => setRegisterReservationId(null)}>
                <ConfirmationModal.Header>Reservering verwerken</ConfirmationModal.Header>
                <Text $noMargin $align="center">Weet je zeker dat je de reservering wilt verwerken?</Text>
                <StyledButton type="button" onClick={() => doRegisterReservation()} disabled={requestStatus !== Statuses.IDLE} loading={requestStatus !== Statuses.IDLE}>Ja, verwerken</StyledButton>
                <CancelButton onClick={() => setRegisterReservationId(null)}>Annuleer</CancelButton>
                {error && <Text $error>Er is iets misgegaan, probeer het opnieuw.</Text>}
            </ConfirmationModal>
        </>
    )
}

function DeferredReservations({ status, removeReservation, registerReservation }) {
    const reservationsData = useAsyncValue()
    const {account} = useRouteLoaderData("root");
    const navigation = useNavigation()
    const isPending = navigation?.location?.pathname?.includes(`/reservation/reservations/${status}`)

    const showRegisterButton = () => {
        return status === 'reserved' || status === 'registered'
    }

    const showUnlinkButton = () => {
        return status === 'reserved' || status === 'registered'
    }

    return (
        <>
            <RelativeWrapper>
                {reservationsData?.items?.length > 0 ? (
                    <>
                        <Pagination searchResults={reservationsData} $position="top"/>
                        <Table>
                            <TableHead>
                                <TableHeadRow>
                                    <TableHeadCell>Naam patient</TableHeadCell>
                                    <TableHeadCell>Medicore ID</TableHeadCell>
                                    <TableHeadCell>Agenda</TableHeadCell>
                                    <TableHeadCell>Afspraakdatum</TableHeadCell>
                                    <TableHeadCell>Gereserveerd op</TableHeadCell>
                                    { showRegisterButton() && <TableHeadCell $flex="0" $minWidth="156px" /> }
                                    { showUnlinkButton() && <TableHeadCell $flex="0" $padding="0 0 15px 0" $minWidth="48px" /> }
                                </TableHeadRow>
                            </TableHead>
                            {reservationsData?.items?.map((item, rowIndex) => {
                                return (
                                    <TableRow key={`row-${rowIndex}`}>
                                        <TableRowInner $isOddRow={rowIndex % 2 === 0}>
                                            <TableRowCell>
                                                <PatientLink to={{pathname: `/patient/${item.patient.id}`}}>
                                                    { item.patient.name }
                                                </PatientLink>
                                            </TableRowCell>
                                            <TableRowCell>{ item.patient.medicoreID }</TableRowCell>
                                            <TableRowCell>{ item.resource }</TableRowCell>
                                            <TableRowCell>
                                                { dayjs(item.appointment.internalDate).format("DD-MM-YYYY") } {item?.appointment?.time}
                                            </TableRowCell>
                                            <TableRowCell>
                                                { dayjs(item.reservedAt).format("DD-MM-YYYY") }
                                            </TableRowCell>
                                            { showRegisterButton() && <TableRowCell $flex="0" $minWidth="156px">
                                                {(checkAccountPermissions(account, Permissions.PARTICIPANT_VIEW)) &&
                                                    <TableRegisterButton onClick={() => registerReservation(item.id)}>{status === "registered" ? "Opnieuw" : "Verwerken"}</TableRegisterButton>
                                                }
                                            </TableRowCell> }
                                            { showUnlinkButton() && <TableRowCell $flex="0" $padding="16px 0" $minWidth="48px">
                                                {(checkAccountPermissions(account, [Permissions.PARTICIPANT_DELETE, Permissions.APPOINTMENT_DELETE])) &&
                                                    <TableRowButton onClick={() => removeReservation(item.id)}>
                                                        <TrashIcon />
                                                    </TableRowButton>
                                                }
                                            </TableRowCell> }
                                        </TableRowInner>
                                    </TableRow>
                                );
                            })}
                        </Table>
                        <Pagination searchResults={reservationsData} />
                    </>
                ) : (
                    <Text>Geen resultaten gevonden</Text>
                )}

                {isPending &&
                    <SpinnerOverlay>
                        <SpinnerWrapper>
                            <Spinner />
                        </SpinnerWrapper>
                    </SpinnerOverlay>
                }
            </RelativeWrapper>
        </>
    )
}

export default Reservations